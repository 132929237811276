.customer {
  background-color: #fff;
  padding-bottom: 4rem;
}
.customerTitle {
  color: rgb(8, 172, 191);
}
.customeContainer {
  padding-top: 2rem;
  display: flbex;
  flex-flow: row;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.customerImg {
  width: 300px;
  height: 100px;
  padding: 0.5rem;
}
