* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.services,
.sign-up,
products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-color: brown;
  /*  background-image: url('/images/img-2.jpg');  */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(8, 172, 191);

  font-size: 100px;
}

.products {
  background-color: rgb(255, 255, 255);
  color: rgb(8, 172, 191);
  font-size: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sign-up {
  background-color: brown;
  /*  background-image: url('/images/img-8.jpg');  */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about-us {
  padding-top: 2rem;
  padding-bottom: 4rem;
  direction: rtl;
  list-style: none;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  line-height: 2.5rem;
  text-align: justify;
  font-size: 18px;
  font-weight: bold;
}

.about-us ul {
  margin-right: 1.5rem;
}
.about-us .about-img {
  width: 70%;
  margin-top: 4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-item {
  margin: 20px;
}
.error-msg {
  color: red;
  font-size: 1em;
  display: block;
  opacity: 0;
}

label {
  margin-right: 20px;
}

.img404 {
  width: 700px;
  padding-top: 100px;
  padding-bottom: 200px;
  margin: auto;
  display: block;
}
