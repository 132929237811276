.Page__title {
  margin-top: 2rem;
  color: rgb(8, 172, 191);
}
.Paragraphs__items {
  padding-top: 2rem;
  padding-bottom: 4rem;
  direction: rtl;
  list-style: none;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.Paragraphs__item {
  text-align: right;
  text-justify: inter-word;
  margin-top: 2rem;
}

.ParTitle {
  font-size: 24px;
  color: rgb(8, 172, 191);
}

.Par__item__text {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
  line-height: 2.5rem;
}
.Par__item__text ul {
  margin-right: 1.5rem;
  list-style: disc;
}
.ParImage__item__text {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
  line-height: 2.5rem;
}
.par_img {
  float: left;
  width: 350px;
  height: 250px;
  margin: 5px;
  margin-right: 1.5rem;
  border-radius: 8px;
}
